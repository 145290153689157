import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import InfoSection from "../../components/infoSection"
import CtaGetCoffee from "../../components/ctaGetCoffee"
import { Link } from "gatsby"

/*
    This example requires Tailwind CSS v2.0+

    This example requires some changes to your config:

    ```
    // tailwind.config.js
    module.exports = {
    // ...
    plugins: [
    // ...
    require('@tailwindcss/aspect-ratio'),
    ],
  }
    ```
    */
const features = [
  {
    name: "1 Siebträger säubern",
    description:
      "Alter Kaffee im Siebträger wirkt sich negativ auf den Geschmack aus. Reinige ihn immer mit einem trockenen Tuch bevor du frischen Kaffee hineinmahlst.",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger1.JPG",
    imageAlt: ""
  },
  {
    name: "2 Siebträger abwiegen",
    description:
      "Um so genauer du arbeitest um so besser, deswegen empfehlen wir eine Waage zu verwenden. Um später die exakte Menge an Kaffeemehl abwiegen zu können, muss der Siebträger auf der Waage tariert werden.",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger2.PNG",
    imageAlt: ""
  },
  {
    name: "3 Bohnen mahlen",
    description:
      "Das beste Ergebnis erzielst du immer mit frisch gemahlenen Bohnen. Mahle die gewünschte Menge immer erst kurz bevor du sie verwendest.",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger3.png",
    imageAlt: ""
  },
  {
    name: "4 Kaffeemehl wiegen",
    description:
      "Für unsere Rezeptempfehlung verwenden wir 18 Gramm Kaffee für 2 Espressi",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger4.png",
    imageAlt: ""
  },
  {
    name: "5 Kaffeemehl verteilen",
    description:
      "Das Kaffeemehl muss gleichmäßig im Siebträger verteilt werden um “channeling” zu verhindern, was sich wiederum negativ auf den Geschmack auswirkt. (Noch einfacher geht es mit einem Leveler).",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger5.png",
    imageAlt: ""
  },
  {
    name: "6 Tampen",
    description:
      "Tamper gerade und gleichmäßig auf dem Kaffeemehl aufsetzen und mit Druck gerade nach unten festdrücken (=tampen). Um immer gleiche Ergebnisse zu erzielen solltest du immer mit der gleichen Kraft tampen. Ein ungleichmäßiges tampen kann auch zu channeling und somit einer ungleichen extraktion führen.",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger6.png",
    imageAlt: ""
  },
  {
    name: "7 Reinigen",
    description:
      "Die “Flügel” und den Rand des Siebträgers einfach mit der Hand von Kaffeebrösel befreien indem darüber gewischt wird. Diese verschmutzen unnötig die Kaffeemaschine und verlängern die Reinigung.",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger7.JPG",
    imageAlt: ""
  },
  {
    name: "8 Flushen",
    description:
      "Für ca 3-5 Sekunden den Wasserbezug starten, um das zu heiße Wasser im Brühkopf heraus zu flushen. Das zu heiße Brühwasser würde den Kaffee verbrennen und zu einem bitteren und verbrannten Geschmack führen.",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger8.png",
    imageAlt: ""
  },
  {
    name: "9 Kaffee beziehen",
    description:
      "Siebträger einspannen und SOFORT (max 3 Sek.) den Wasserbezug starten. Du hast 3-6 Sekunden bevor der Kaffee kommt, was genügend Zeit ist, um die Tassen unter zu stellen. Lässt du dir hier zu lange Zeit verbrennt der heiße Brühkopf den Kaffee was wiederum zu einem bitteren Geschmack führt.",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger9.png",
    imageAlt: ""
  },
  {
    name: "10 Genießen",
    description:
      "Nach 25 Sekunden solltest du nun 50 Millilitter/Gramm Kaffee in deiner Tasse haben. Rinnt der Kaffee zu schnell hinaus, d.h. du erreichst die 50 Gramm schon vor den 25 Sekunden, solltest du den Mahlgrad deiner Mühle feiner stellen. Rinnt der Kaffee zu langsam hinaus, solltest du deinen Mahlgrad gröber stellen. Solltest du keinen Platz für deine Waage unter deinem Siebträger haben, kannst du auch die Tasse vorher und nachher abwiegen und beim Wasser beziehen im Kopf bist 25 zählen oder mit deinem Handy stoppen.",
    imageSrc: "/brewguide/siebtraeger/Siebtraeger10.png",
    imageAlt: ""
  }
]

const tippsntricks = [
  {
    title: "Sauberkeit",
    description: "So einfach es klingt, aber die Sauberkeit deiner Maschine ist ausschlaggebend für einen guten Geschmack. Deswegen solltest du deine Maschine regelmäßig im besten Falle sogar täglich reinigen. Somit verlängerst du die Lebenszeit deiner Maschine und hast auch einen konstant guten Geschmack."
  },
  {
  title: "Genauigkeit",
  description: "Um konstant guten Kaffee zu erhalten muss genau gearbeitet werden. Wir empfehlen es die gemahlene Kaffeemenge immer abzuwiegen, um sicher zu gehen, dass sie gleich ist. Hierbei wird der Siebträger auf die Waage gestellt und tariert. Danach wird das Kaffeemehl gemahlen und der Siebträger mit dem Kaffeemehl erneut gewogen."
},
  {
    title: "Veränderung des Rezepts",
    description: "Um wirklich einen Unterschied im Espresso zu erkennen, sollte immer nur EIN Parameter (Menge, Mahlgrad, Bezugszeit, Temperatur, Druckprofil) bei der Zubereitung verändert werden. Nur so kann ein wirklicher Unterschied zum vorherigen Shot hergestellt und bewertet werden, ob die Veränderung zu einer Verbesserung oder Verschlechterung geführt hat."
  },{
    title: "Mahlgrad",
    description: "Der Mahlgrad für Espresso ist allgemein sehr fein. Hellere Röstungen können feiner gemahlen werden, da die Bohnen nicht unkontrolliert splittern. Dunklere Röstungen sollten größer, aber immer noch fein, gemahlen werden"
  },{
    title: "Brew Ratio",
    description: "Für Espresso empfiehlt es sich eine brew ratio von 1:2 oder 1:2,5 - 1:2 bedeutet, dass bei 18g Kaffeemehl 36ml Kaffee nach 25 Sekunden in der Tasse sind."
  },{
    title: "Wassertemperatur",
    description: "Temperatur zwischen 88 und 96 Grad Helle Röstungen können heißer gebrüht werden. Dunklere Röstungen sollten “kälter” gebrüht werden."
  },{
    title: "Wasserdruck",
    description: "Der Druck bei einem richtigen Espresso muss 9 bar betragen. Am Anfang des Bezuges sollten nicht gleich 9 Bar am Siebträger anliegen. Der Druck sollte stufenweise aufgebaut werden was bei den meisten Siebträgermaschine der Fall ist."
  }
]

const SiebtraegerPage = () => (
  <Layout>
    <Seo title="Brew Guide" />

    <div className="bg-white">
      <div aria-hidden="true" className="relative">

        <StaticImage src="../../images/janko-ferlic-pR9D-7ztGmA-unsplash.jpg" alt="brew guide siebträger von caffeinity"
                     className="w-full h-96 object-center object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-white" />
      </div>

    </div>


    <div>
      <div className="max-w-7xl mx-auto py-24 sm:py-32 sm:px-2 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="max-w-6xl text-center">
            <p className="mt-2 text-6xl font-extrabold tracking-tight sm:text-4xl text-caffeinity-green-dark">Siebträger</p>
            <p className="mt-4 text-xl text-gray-600 text-justify">
              Deine einfache Schritt für Schritt Anleitungen zu einem außergewöhnlichen Kaffee-Erlebnis für Siebträger.
            </p>
          </div>

          <div className="space-y-16 pt-10 mt-10 border-t border-gray-200 sm:pt-16 sm:mt-16">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
              >
                <div className="flex-auto lg:col-span-7 xl:col-span-8">
                  <div className="aspect-w-5 aspect-h-5 rounded-lg bg-gray-100 overflow-hidden">
                    <img src={feature.imageSrc} alt={feature.imageAlt} className="object-center object-cover" />
                  </div>
                </div>
                <div className="mt-6 lg:mt-0 lg:col-span-5 xl:col-span-4">
                  <h3 className="text-4xl font-bold text-caffeinity-green-dark">{feature.name}</h3>
                  <p className="mt-2 text-xl text-gray-600 mb-4 text-justify">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
          <Link
            to="/brewguide"
            className="btn-primary mt-24">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
            </svg> {' '}
            Zurück zu allen Brew Guides
          </Link>
        </div>
      </div>
    </div>

    <h1 className="bg-caffeinity-brown text-center py-14">Tipps & Tricks</h1>
    {tippsntricks.map((tipps) => (
      <InfoSection className="bg-caffeinity-pink text-caffeinity-green-dark" textHeading={tipps.title} textSubtitle={tipps.description} />
    ))}

    <CtaGetCoffee/>


  </Layout>
)

export default SiebtraegerPage
